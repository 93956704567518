<template>
  <div class="right_connent page my_center" ref="my_center">
    <div class="title">个人资料</div>
    <div class="connent_wrap">
      <el-form
        :label-position="labelPosition"
        :model="my_center_form"
        :inline="true"
        :rules="rules"
        ref="my_center_form"
      >
        <div class="item_wrap avator">
          <div class="label">头 像</div>
          <div class="img_wrap">
            <img :src="user.userlogo" alt="" />
          </div>
          <div class="uplond" @click="uploadUserLogo">选择文件</div>
        </div>
        <el-form-item label="昵 称" prop="pname" :label-width="formLabelWidth">
          <el-input
            style="width: 465px"
            v-model="my_center_form.pname"
            autocomplete="off"
            placeholder="请输入您的昵称"
            clearable
            :label-width="formLabelWidth"
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="报考专业"
          :label-width="formLabelWidth"
          prop="major"
        >
          <el-select
            style="width: 280px"
            v-model="my_center_form.major"
            placeholder="请选择报考专业"
          >
            <el-option
              v-for="item in red_master_list"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          style="margin-left: 20px"
          label="考研年份"
          :label-width="formLabelWidth"
          prop="year"
        >
          <el-select
            style="width: 280px"
            v-model="my_center_form.year"
            placeholder="请选择考研年份"
          >
            <el-option
              v-for="item in prepare_year_list"
              :key="item.value"
              :label="item.prepare_year_name"
              :value="item.prepare_year"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer_wrap">
        <el-button
          type="primary"
          v-preventReClick
          @click="onSubmit('my_center_form')"
          >保存</el-button
        >
      </div>
    </div>
    <el-dialog title="头像设置" :visible.sync="avatarDialogVisible" width="500">
      <div class="cropper-component">
        <!-- 展示内容部分 -->
        <div class="show_box">
          <div class="l">
            <div class="text">
              当前剪辑尺寸为：{{ setWidth }} X {{ setHeight }}
            </div>
            <!-- 展示选中图片 -->
            <div
              class="cropper"
              :style="{
                width: setWidth / 2 + 'px',
                height: setHeight / 2 + 'px',
              }"
            >
              <vueCropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="option.info"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :enlarge="option.enlarge"
                :fixedBox="option.fixedBox"
                @realTime="realTime"
                @imgLoad="imgLoad"
              ></vueCropper>
            </div>
            <!-- 工具箱部分 -->
            <div class="btn-box">
              <div class="scope-btn">
                <label class="btn" for="uploads">选择头像</label>
                <input
                  type="file"
                  id="uploads"
                  style="position: absolute; clip: rect(0 0 0 0)"
                  accept="image/png, image/jpeg, image/gif, image/jpg"
                  @change="uploadImg($event, 'blob', 1)"
                />
                <el-button size="mini" type="danger" plain @click="rotateLeft"
                  >↺ 左转</el-button
                >
                <el-button size="mini" type="danger" plain @click="rotateRight"
                  >↻ 右转</el-button
                >
                <!-- <el-button
              size="mini"
              type="primary"
              plain
              icon="el-icon-download"
              @click="down('blob')"
              >下载</el-button
            > -->
              </div>
            </div>
          </div>
          <div class="r">
            <!-- 展示缩略图 -->
            <div class="preview-box">
              <div class="text">预览：</div>
              <div class="preview_wrap">
                <div :style="previews.div" class="preview">
                  <img :src="previews.url" :style="previews.img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="avatarDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="submitAvatar('blob')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cookieGO, getCookie } from "@/config/cookie";
import { mapActions } from "vuex";
import tab from "@/components/tab.vue";
import api from "@/config/api";
import { host } from "@/config/config.js";
import { randomWord } from "@/assets/common/js/method.js";
var COS = require("cos-js-sdk-v5");
var Bucket = "successkaoyan-1254014383";
var Region = "ap-beijing"; /* 存储桶所在地域，必须字段 */

// 初始化实例
var cos = new COS({
  Domain: "success-image.successkaoyan.com",
  Protocol: "https:",
  getAuthorization: function (options, callback) {
    var url = host + "cos_s";
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.onload = function (e) {
      try {
        var data = JSON.parse(e.target.responseText);
      } catch (e) {}
      callback({
        TmpSecretId: data.credentials && data.credentials.tmpSecretId,
        TmpSecretKey: data.credentials && data.credentials.tmpSecretKey,
        XCosSecurityToken: data.credentials && data.credentials.sessionToken,
        ExpiredTime: data.expiredTime,
      });
    };
    xhr.send();
  },
});
//获取年月日
var dateimg = new Date();
var yearimg = dateimg.getFullYear() + "";
var monthimg = dateimg.getMonth() + 1 + "";
var dayimg = dateimg.getDate() + "";
var simg = dateimg.getTime() + "";
if (monthimg < 10) {
  monthimg = "0" + monthimg;
}

if (dayimg < 10) {
  dayimg = "0" + dayimg;
}
var timeimg = yearimg + monthimg + dayimg;
export default {
  //   name: "my_order",
  props: {
    setWidth: {
      type: Number,
      default: 400,
    },
    setHeight: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      labelPosition: "right",
      formLabelWidth: "100px",
      red_master_list: [],
      my_center_form: {
        pname: "",
        major: "",
        year: "",
      },
      prepare_year_list: [],
      rules: {
        pname: [{ required: true, message: "请填写昵称", trigger: "blur" }],
        major: [
          { required: true, message: "请选择报考专业", trigger: "change" },
        ],
        year: [{ required: true, message: "请选择考研年份", trigger: "blur" }],
      },
      user: {},
      logined: false,
      open: false,

      avatarDialogVisible: false,
      previews: {},
      option: {
        img: "", // 裁剪图片的地址
        info: false, // 裁剪框的大小信息
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        outputType: "jpg", // 裁剪生成的图片的格式
        canMove: true, // 上传图片是否可以移动
        original: false, // 上传图片是否显示原始宽高
        canMoveBox: true, // 截图框能否拖动
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: this.setWidth / 3,
        autoCropHeight: this.setHeight / 3,
        fixedBox: true, // 截图框固定大小
        fixed: false, //是否开启截图框宽高固定比例
        enlarge: 3, //图片根据截图框输出比例倍数
      },
      downImg: "#",
    };
  },
  created() {
    // this.getData();
    this.get_red_major();
    this.get_personal_data();
  },
  methods: {
    ...mapActions(["setUserAndState"]),
    // async getData() {
    //   let islogin = await this.islogin();
    //   if (islogin) {
    //     this.logined = true;
    //     this.user = this.$store.state.user;
    //     this.my_center_form.pname = this.user.pname;
    //     // this.my_center_form.userlogo = this.user.userlogo;
    //   } else {
    //     this.logined = false;
    //     this.user = {};
    //   }
    // },
    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },

    async get_red_major() {
      try {
        const res = await api.red_major({
          action: "major",
        });
        if (res.code == 0) {
          this.red_master_list = res.result;
        } else {
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async get_personal_data() {
      try {
        const res = await api.personal_data();
        if (res.code == 0) {
          let { prepare_year_list, oneself } = res.result;
          this.prepare_year_list = prepare_year_list;
          // this.my_center_form = {
          //   pname: oneself.pname,
          //   year: oneself.prepare_year.prepare_year,
          //   major:oneself.apply_major.major_three_level_code
          // }
          this.my_center_form.pname = oneself.pname;
          this.user.userlogo = oneself.userlogo;
          this.my_center_form.year = oneself.prepare_year.prepare_year;
          this.my_center_form.major = Number(
            oneself.apply_major.major_three_level_code
          );
        } else {
        }
      } catch (e) {
        // console.log(e);
      }
    },

    //  更新用户信息
    async upsave_info(prams) {
      try {
        let res = await api.save_info(prams);
        if (res.code == 0) {
          if (prams.pname) {
            let old_userInfo = this.$store.state.user;
            old_userInfo.pname = prams.pname;
            cookieGO("userInfo", JSON.stringify(old_userInfo));
            this.setUserAndState(old_userInfo);
          }
          if (prams.userlogo) {
            // this.oneself.userlogo = prams.userlogo;
            let old_userInfo = this.$store.state.user;
            old_userInfo.userlogo = prams.userlogo;
            cookieGO("userInfo", JSON.stringify(old_userInfo));
            this.setUserAndState(old_userInfo);
          }
          this.$notify({
            title: "提示信息",
            message: "更新信息成功",
            type: "success",
          });
        } else {
          this.$notify({
            title: "提示信息",
            message: "更新信息失败",
            type: "error",
          });
        }
      } catch (e) {
        // console.log(e);
      }
    },

    onSubmit(my_center_form) {
      this.$refs[my_center_form].validate((valid) => {
        if (valid) {
          var choose_code = "";
          this.red_master_list.forEach((v) => {
            v.code == this.my_center_form.major ? (choose_code = v.code) : "";
          });
          var prams = {
            major_three_level_code: choose_code,
            pname: this.my_center_form.pname,
            prepare_year: this.my_center_form.year,
            userlogo: this.user.userlogo,
          };
          setTimeout(() => {
            this.upsave_info(prams);
          }, 500);
        } else {
          console.log("error submit!!");

          return false;
        }
      });
      setTimeout(() => {
        this.$refs[my_center_form].clearValidate();
      }, 2000);
    },
    // 选择文件
    uploadUserLogo() {
      this.avatarDialogVisible = true;
    },
    // 修改头像提交
    submitAvatar(type) {
      let _this = this;
      if(!this.option.img){
        this.$message('您还没有选择头像~')
        return
      }
      if (type === "blob") {
        //获取截图的blob数据
        this.$refs.cropper.getCropBlob(async (data) => {
          let random_num = randomWord(false, 15);
          let nowTime_s = new Date().getTime();
          cos.putObject(
            {
              Bucket: Bucket /* 必须 */,
              Region: Region /* 存储桶所在地域，必须字段 */,
              Key:
                "userlogo/user/" +
                timeimg +
                "/" +
                random_num +
                nowTime_s +
                ".jpg" /* 必须 */,
              StorageClass: "STANDARD",
              Body: data, // 上传文件对象
              onProgress: function (progressData) {
                // console.log(JSON.stringify(progressData));
              },
            },
            function (err, data) {
              // console.log(err || data);
              if (data) {
                _this.$forceUpdate();
                // _this.$set( user, userlogo,  'https://'+data.Location )
                _this.user.userlogo = "https://" + data.Location;
                _this.avatarDialogVisible = false;
              } else {
                // uni.showToast({
                //   title: "上传失败",
                //   icon: "none",
                // });
              }
            }
          );
        });
      }
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    // 选择本地图片
    uploadImg(e, type, num) {
      //num代表第几个
      // 上传图片
      var file = e.target.files[0];
      // if (!/\.(jpg)$/.test(e.target.value)) {
      //   this.$Message.info('证件照图片必须是jpg格式');
      //   return false;
      // }
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = ""; //生成图片地址
        if (typeof e.target.result === "object") {
          if (type == "blob") {
            // 把Array Buffer转化为blob 如果是base64不需要
            data = window.URL.createObjectURL(new Blob([e.target.result]));
          }
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          this.option.img = data;
        }
      };
      if (type == "blob") {
        // 转化为blob
        reader.readAsArrayBuffer(file);
      } else {
        // 转化为base64
        reader.readAsDataURL(file);
      }
    },
    //图片加载的回调 imgLoad 返回结果success, error
    imgLoad(msg) {},
    // 坐旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 下载图片
    down(type) {
      var aLink = document.createElement("a");
      let timer = new Date().getTime();
      aLink.download = timer; //文件名
      if (type === "blob") {
        // 获取截图的blob数据
        this.$refs.cropper.getCropBlob((data) => {
          this.downImg = window.URL.createObjectURL(data); //生成blob格式图片路径
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        // 获取截图的base64 数据
        this.$refs.cropper.getCropData((data) => {
          this.downImg = data;
          aLink.href = data;
        });
      }
    },
  },
  components: {
    tab,
  },
};
</script>
<style>
.my_center .el-form-item__label {
  padding: 0 21px 0 0;
  font-size: 16px;

  font-weight: 400;
  color: #333333;
}
.footer_wrap .el-button {
  width: 160px;
  height: 40px;
  /* background: #479dff; */
  border-radius: 20px;
}
</style>

<style lang="less" scoped>
// 头像设置---------------------------start
.cropper-component {
  // width: 80%;
  // height: 500px;
  // margin: 0 auto;
  // position: relative;
  .text {
    margin-bottom: 10px;
  }
  //工具箱部分
  .btn-box {
    margin: 20px 0;
    .btn {
      outline: none;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      -webkit-appearance: none;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      -webkit-transition: 0.1s;
      transition: 0.1s;
      font-weight: 500;
      padding: 8px 15px;
      font-size: 12px;
      border-radius: 3px;
      color: #fff;
      background-color: #479dff;
      border-color: #479dff;
      margin-right: 10px;
    }
  }
  //展示内容部分
  .show_box {
    display: flex;
    justify-content: center;
    // 展示选中图片
    // .cropper {
    //     width: 500px;
    //     height: 500px;
    // }
    // 展示缩略图
    .preview-box {
      margin-left: 100px;
      // top: 60px;
      // left: 10px;
      .preview_wrap {
        width: 200px;
        height: 200px;
        background-color: #ccc;
        position: relative;
        .preview {
          // border-radius: 50%;//这里预览是否需要变成圆的
          background-color: #ccc;
          // margin: 5px;
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
.dialog-footer .el-button--primary {
  background-color: #479dff;
  border-color: #479dff;
}
// 头像设置---------------------------end
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;

    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.right_connent {
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;

    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 30px;
  }
  .connent_wrap {
    .in_Line {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .item_wrap {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .label {
        width: 80px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        text-align: right;
        margin-right: 20px;
      }
    }
    .avator {
      .img_wrap {
        width: 100px;
        height: 100px;
        // background: #479dff;
        border-radius: 50%;
      }
      img {
        width: 100px;
        height: 100px;
        background: #479dff;
        border-radius: 50%;
      }
      .uplond {
        width: 99px;
        height: 34px;
        line-height: 34px;
        border: 1px solid #e0e0e0;
        border-radius: 17px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        text-align: center;
        margin-left: 18px;
        cursor: pointer;
      }
      .uplond:hover {
        background-color: #479dff;
        color: #fff;
      }
    }
  }
}
.footer_wrap {
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
}
</style>
